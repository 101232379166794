exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-employment-page-js": () => import("./../../../src/templates/EmploymentPage.js" /* webpackChunkName: "component---src-templates-employment-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-people-page-js": () => import("./../../../src/templates/PeoplePage.js" /* webpackChunkName: "component---src-templates-people-page-js" */),
  "component---src-templates-person-page-js": () => import("./../../../src/templates/PersonPage.js" /* webpackChunkName: "component---src-templates-person-page-js" */),
  "component---src-templates-practice-page-js": () => import("./../../../src/templates/PracticePage.js" /* webpackChunkName: "component---src-templates-practice-page-js" */),
  "component---src-templates-project-list-page-js": () => import("./../../../src/templates/ProjectListPage.js" /* webpackChunkName: "component---src-templates-project-list-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/ProjectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-recognitions-list-page-js": () => import("./../../../src/templates/RecognitionsListPage.js" /* webpackChunkName: "component---src-templates-recognitions-list-page-js" */),
  "component---src-templates-selected-work-page-js": () => import("./../../../src/templates/SelectedWorkPage.js" /* webpackChunkName: "component---src-templates-selected-work-page-js" */),
  "component---src-templates-story-list-page-js": () => import("./../../../src/templates/StoryListPage.js" /* webpackChunkName: "component---src-templates-story-list-page-js" */),
  "component---src-templates-story-page-js": () => import("./../../../src/templates/StoryPage.js" /* webpackChunkName: "component---src-templates-story-page-js" */)
}

