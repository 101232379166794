import React from "react";
import styled from "styled-components";
import { useBoundStore } from "../../../stores/useBoundStore";

const Holder = styled.div`
  cursor: pointer;
  position: fixed;
  height: var(--windowHeight);
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s ease-out;
  background: black;
  overflow: hidden;

  &.hide {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    gap: 0.5rem;

    margin: auto 2rem;

    p {
      color: white;
      margin: 0;
      text-align: center;
      ${(props) => props.theme.fluidType(2)}
    }
  }
`;

const OpeningScreen = () => {
  const setShowOverlay = useBoundStore((state) => state.setShowOverlay);
  const showOverlay = useBoundStore((state) => state.showOverlay);
  return (
    <Holder
      onClick={() => {
        setShowOverlay(false);
      }}
      className={`overlay ${showOverlay ? "show" : "hide"}`}
    >
      <div className="annotation-body">
        <p>
          SMM is located on the traditional lands of the Gadigal of the Eora
          Nation. We acknowledge the Traditional Custodians of Country
          throughout Australia and their connections to land, sea and community.
          We pay our respect to Aboriginal and Torres Strait Islander Elders
          past and present, and recognise and respect their abiding connection
          to this land, its waterways and community.
        </p>
      </div>
    </Holder>
  );
};

export default OpeningScreen;

