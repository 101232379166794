import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../utils/fontface.css";
import GlobalStyles from "../components/atoms/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/styling";
import { useWindowSize } from "react-use";
import { useBoundStore } from "../../stores/useBoundStore";
import OpeningScreen from "../components/atoms/OpeningScreen";

function Index({ children, location }) {
  const size = useWindowSize();
  const setCurrentLink = useBoundStore((state) => state.setCurrentLink);

  const showOverlay = useBoundStore((state) => state.showOverlay);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--windowHeight",
      `${size.height}px`
    );
    setCurrentLink(location);
  }, [size, location, setCurrentLink]);

  const ausHome = location.pathname === "/en-au/";

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {ausHome && showOverlay && <OpeningScreen />}
        <main>{children}</main>
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;

