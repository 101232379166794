module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"smm-website","accessToken":"MC5aR2RQeWhBQUFDSUFwSGhz.Q0fvv73vv73vv73vv73vv71177-977-977-977-9NO-_vUtX77-977-977-977-977-977-9LSV-77-9Le-_vS3vv73vv714","routes":[{"type":"project","path":"/:lang/work/:uid/"},{"type":"story","path":"/:lang/stories/:uid/"},{"type":"homepage","path":"/:lang/"},{"type":"practice","path":"/:lang/practice/"},{"type":"person","path":"/:lang/people/:uid/"},{"type":"employment","path":"/:lang/employment/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"great-start","short_name":"great-start","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84b7ee3e1096862234022a3d71dbc5ab"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
