import { create } from "zustand";

export const useBoundStore = create((set) => ({
  showMenu: false,
  setShowMenu: (value) => set({ showMenu: value }),
  currentLink: null,
  setCurrentLink: (value) => set({ currentLink: value }),
  showOverlay: true,
  setShowOverlay: (value) => set({ showOverlay: value }),
  navExpanded: false,
  setNavExpanded: (value) => set({ navExpanded: value }),
}));

