import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  :root {
    --windowHeight: 100vh;
    --yellow: #FAE132;
    ;
  }
  
  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      ${(props) => props.theme.fluidType(0)};
      * { box-sizing: border-box; }
  }

  body {
      margin: 0;
      font-family: "Galaxie Copernicus", serif;
      color: ${(props) => props.theme.colours.black};
      background: white;
  }

  .sans {
    font-family: "Universal Sans", sans-serif;
  }

  .serif {
    font-family: "Galaxie Copernicus", serif;
  }

  .annotation-body, .annotation-body * {
    ${(props) => props.theme.fluidType(0)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-1.8)}
      line-height: 1.5;

    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.36;

    strong {
      font-weight: bold;
    }

    a {
      cursor: pointer;
      line-height: 1.5;
    }
   
  }

  .header, .header * {

    ${(props) => props.theme.fluidType(2)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-2)}
    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
   
  }

  .homepage-annotation, .homepage-annotation * {
 
    ${(props) => props.theme.fluidType(2)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-2)}
    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.5;

    strong {
      font-weight: bold;
    }

    a {
      cursor: pointer;
      line-height: 1.5;
    }
   
  }

  .heading-xl *, .heading-xl {
    ${(props) => props.theme.fluidType(19)}
    font-weight: normal;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.3;
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(10.5)}
    }

  }

  .heading-l *, .heading-l {
${(props) => props.theme.fluidType(13)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(9)}
    }
    
    font-weight: normal;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.2;
  }

  .heading-story {
    ${(props) => props.theme.fluidType(7)}
    font-weight: normal;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.2;
  }

  .heading-m, .heading-m * {
    ${(props) => props.theme.fluidType(12.7)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(4)} }
    font-weight: bold;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.6;
  }

  .heading-s, .heading-s * {
    ${(props) => props.theme.fluidType(4)}
    font-weight: normal;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.6;
  }

  .heading-xs,  .heading-xs * {
    ${(props) => props.theme.fluidType(5.5)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(4.2)}
    }
    font-weight: medium;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.4;
  }

  .project-module-l, .project-module-l * {
    ${(props) => props.theme.fluidType(9.5)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(5)}
    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.2;
    margin: 0.8rem 0;
  }

  .project-module-m, .project-module-m * {
    ${(props) => props.theme.fluidType(9)}
    line-height: 1.1;
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(1.5)} 
      line-height: 1.3;

    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
   
  }
  
  .project-module-s, .project-module-s * {
    ${(props) => props.theme.fluidType(5.5)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(0)}
    }
    font-weight: normal;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.3;
    padding-bottom: 0.2rem;
  }

  .project-sub-l,  .project-sub-l * {
    ${(props) => props.theme.fluidType(9.5)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(4)}
    }
    font-style: italic;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.2;
  }

  .project-sub-m, .project-sub-m * {
    ${(props) => props.theme.fluidType(7)}
    line-height: 1.45;
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(0.8)}
      line-height: 1.55;
    }
    font-style: italic;
    font-family: "Galaxie Copernicus", serif;
    
  }

  .project-sub-s, .project-sub-s * {
    ${(props) => props.theme.fluidType(3.9)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-0.8)}
    }
    font-style: italic;
    font-family: "Galaxie Copernicus", serif;
    line-height: 1.3;
  }

  .annotation-heading, .annotation-heading * {
    ${(props) => props.theme.fluidType(-2)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-3)}
    }
    font-weight: bold;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.6;
  }


  .photo-credit, .photo-credit * {
    ${(props) => props.theme.fluidType(0)}
    @media (${(props) => props.theme.breakpoints.sm}) {
      ${(props) => props.theme.fluidType(-1.8)}
      line-height: 1.5;
    }
    font-style: normal;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.36;
    opacity: 50%;
    color: #000000;
    margin: 0.4rem 0;

    strong {
      font-weight: bold;
    }

  }


  .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}

  



  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      line-height: 1.1;
      margin-top: 1rem;
      margin-bottom: 1rem;
  }
  
  p,
  ol, ul, li,
  code, kbd, pre, samp {

      // Changed from 2 to 1.8
      line-height: 1.8;

      margin-top: 0.8rem;
      margin-bottom: 0.8rem;

      // Changed from 4 to 5.5
      ${(props) => props.theme.fluidType(5.5)}

      @media (${(props) => props.theme.breakpoints.sm}) {
        ${(props) => props.theme.fluidType(0)}
      }

  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      a { text-decoration: none; }
      a:hover { text-decoration: none; }
  }

  h1, .h1 { ${(props) => props.theme.fluidType(4)}; }
  h2, .h2 { ${(props) => props.theme.fluidType(3)}; }
  h3, .h3 { ${(props) => props.theme.fluidType(2)}; }
  h4, .h4 { ${(props) => props.theme.fluidType(1)}; }
  h5, .h5 { ${(props) => props.theme.fluidType(0)}; }
  h6, .h6 { ${(props) => props.theme.fluidType(-1)}; }

  li {
      margin-top: 0;
      margin-bottom: 0;
  }

  small, p.small { ${(props) => props.theme.fluidType(-1)}; }
  
  code, kbd, pre, samp {
      font-family: monospace;
      white-space: normal;
  }
  
  ul {
      padding-left: 0;
      list-style-type: none;
  }
  
  ol {
      padding-left: 1rem;
      list-style-type: decimal;
  }
  
  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  // Specific to PrismicRichText component
  .block-img {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 3rem 0;
    }
  }
  
  // Specific to PrismicRichText component
  .embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 3rem 0;

    h2 {
      ${(props) => props.theme.fluidType(1.5)};
    font-weight: medium;
    font-family: "Universal Sans", sans-serif;
    line-height: 1.2;
    }


    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: bold; }
  
  blockquote {
      font-weight: bold;
      padding-left: 1rem;
  }
  
  a { 
    color: inherit;
    text-decoration: none;
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
      ${(props) => props.theme.fluidType(-1)};
      line-height: 1.2;
      font-weight: normal;
  }

  .fieldset {
    margin: 0.5rem 0;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${(props) => props.theme.fluidType(0)};
      padding: 0.2rem 0.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 1px solid;
      border-radius: 2px;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
          border: 1px ${(props) => props.theme.colours.focus} solid;
      }
  }

  ::placeholder {
        color: ${(props) => props.theme.colours.black};
        opacity: 0.6;
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  // Select

  // select {
  //   // A reset of styles, including removing the default dropdown arrow
  //   appearance: none;
  //   // Additional resets for further consistency
  //   background-color: transparent;
  //   border: none;
  //   padding: 0;
  //   margin: 0;
  //   width: 100%;
  //   height: 2rem;
  //   font-family: inherit;
  //   font-size: inherit;
  //   cursor: inherit;
  //   line-height: inherit;
  //   outline: none;
  //   color: ${(props) => props.theme.colours.black};
  // }
  //
  // select::-ms-expand {
  //   display: none;
  // }
  //
  // .fieldset.select {
  //   width: 100%;
  //   border-bottom: 1px solid;
  //   border-radius: 0;
  //   padding: 0;
  //   margin: 0.5rem 0 1.5rem 0;
  //   ${(props) => props.theme.fluidType(0)};
  //   cursor: pointer;
  //   line-height: 1.1;
  //   background-color: transparent;
  //   grid-template-areas: "select";
  //   display: grid;
  //   align-items: center;
  //   max-width: 15rem;
  //   &:after {
  //     content: "";
  //     width: 0.8rem;
  //     height: 0.5rem;
  //     background-color: ${(props) => props.theme.colours.black};
  //     clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  //     justify-self: end;
  //   }
  //   select,
  //   &:after {
  //     grid-area: select;
  //   }
  // }

  .strip-button-styles {
    background-color: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: none;
    line-height: inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
  
      padding: 0 1rem;
  
      font-family:inherit;
      ${(props) => props.theme.fluidType(0)};
      line-height: 2;
      text-decoration: none;
      white-space: nowrap;
      border: 1px solid;
      border-radius: 2px;
  
      color: inherit;
      background-color: transparent;
      
      &:hover {
          text-decoration: none;
      }
      &.link {
        border: none;
        padding: 0;
        background-color: transparent;
      }
      &.icon {
        border: none;
        padding: 0;
        background-color: transparent;
        svg {
          height: 3rem;
          width: auto;
          display: block;
        }
      }
  }
  
  .errorMsg {
      color: ${(props) => props.theme.colours.error};
  }
`;

export default GlobalStyle;

