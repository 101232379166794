const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === "project") return `/${doc.lang}/work/${doc.uid}/`;
  if (doc.type === "story") return `/${doc.lang}/stories/${doc.uid}/`;
  if (doc.type === "person") return `/${doc.lang}/people/${doc.uid}/`;
  if (doc.type === "homepage") return `/${doc.lang}/`;
  if (doc.type === "employment") return `/${doc.lang}/employment/`;
  if (doc.type === "practice") return `/${doc.lang}/practice/`;

  // Backup for all other types
  return `/${doc.lang}`;
};

module.exports = linkResolver;

